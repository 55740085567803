<template>
  <h5 class="heading">
    <slot>Please provide the heading Content</slot>
  </h5>
</template>

<style scoped lang="scss">
.heading {
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
</style>
