<template>
  <div class="label-input">
    <label :for="inputId">{{ convertSnakeCaseToTitle(inputId) }}</label>
    <InputText
      @input="emit('unsavedChanges')"
      @change="inputChangeHandler"
      :value="modelValue"
      :disabled="inputDisabled"
      :id="inputId"
      :required="isRequired"
      :type="inputType"
      :min="inputType === 'number' && 0"
    />
    <InvalidInputMessage :wrongTextMessage="wrongTextMessage" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import helpers from "@/services/helpers";
import InvalidInputMessage from "./InvalidInputMessage.vue";

const { convertSnakeCaseToTitle } = helpers;

defineProps({
  modelValue: {
    required: true,
  },
  inputId: {
    type: String,
    required: true,
  },
  inputDisabled: {
    type: Boolean,
    required: true,
  },
  isRequired: {
    type: Boolean,
    required: false,
  },
  inputType: {
    type: String,
    required: false,
  },
});

const emit = defineEmits(["update:modelValue", "unsavedChanges"]);
const wrongTextMessage = ref(null);

const inputChangeHandler = (event) => {
  const userEnteredText = event.target.value;
  emit("update:modelValue", userEnteredText);

  if (userEnteredText.trim() === "") {
    wrongTextMessage.value = "this field can't be empty.";
  } else {
    wrongTextMessage.value = null;
  }
};
</script>

<style scoped lang="scss">
.label-input {
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
}
</style>
