<template>
  <div class="container-search-bar">
    <div class="cotnainer-inputs">
      <search-input
        :onKeyDown="searchTableProducts"
        type="text"
        placeholder="Product Name"
        @input="handleName"
        :value="product_name"
      ></search-input>
      <search-input
        :onKeyDown="searchTableProducts"
        type="text"
        placeholder="Product ID"
        @input="handleProductId"
        :value="product_id"
      ></search-input>
      <search-input
        :onKeyDown="searchTableProducts"
        type="text"
        placeholder="Variant ID"
        @input="handleVariantId"
        :value="variant_id"
      ></search-input>
    </div>
    <base-button @click="searchTableProducts">
      <span class="search-text"> Search in merchant center </span>
      <span class="search-icon">
        <i class="pi pi-search"></i>
      </span>
    </base-button>
    <base-button @click="clearTableFilters" v-tooltip.left="'Reset Filters'">
      <i class="pi pi-filter-slash" style="font-size: 1rem"></i>
    </base-button>
    <div
      style="
        padding-left: 20px;
        border-left: 1px solid rgb(229, 231, 235);
        margin-left: auto;
      "
    >
      <base-button
        @click="refreshTableData"
        class="flat"
        v-tooltip.left="'Refresh Table'"
      >
        <i class="pi pi-refresh" style="font-size: 14px"></i>
      </base-button>
    </div>
  </div>
  <hr />
</template>

<script>
import emitter from "@/services/eventBus";
import SearchInput from "../UI/SearchInput.vue";

export default {
  components: { SearchInput },
  data() {
    return {
      product_name: "",
      product_id: "",
      variant_id: "",
    };
  },
  methods: {
    handleName(event) {
      this.product_name = event.currentTarget.value;
    },
    handleProductId(event) {
      this.product_id = event.currentTarget.value;
    },
    handleVariantId(event) {
      this.variant_id = event.currentTarget.value;
    },
    searchTableProducts(event) {
      // preform search on click and on keydown enter key only
      if (event.type === "keydown" && event.keyCode === 13) {
        event.preventDefault();
      } else if (event.type === "click") {
        event.preventDefault();
      } else {
        return;
      }
      // emit event to notify tables components with searching critera
      emitter.emit("filter-table-data", {
        product_name: this.product_name,
        product_id: this.product_id,
        variant_id: this.variant_id,
      });
      //clear all inputs
      // setTimeout(() => {
      //   this.resetInputs();
      // }, 0);
    },
    clearTableFilters() {
      emitter.emit("clear-table-filters");
      this.resetInputs();
    },
    refreshTableData() {
      emitter.emit("refresh-table-data");
    },
    resetInputs() {
      this.variant_id = "";
      this.product_id = "";
      this.product_name = "";
    },
  },
};
</script>

<style scoped lang="scss">
.container-search-bar {
  padding: 17px 28px;
  display: flex;
  width: 100%;
  gap: 9px;
  button {
    white-space: nowrap;
  }
}
.cotnainer-inputs {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 9px;
}
.search-icon {
  display: none;
}

@media only screen and (max-width: 1150px) {
  .cotnainer-inputs {
    grid-template-columns: 1fr 1fr;
    row-gap: 9px;
  }
}
@media only screen and (max-width: 910px) {
  .cotnainer-inputs {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 680px) {
  .search-text {
    display: none;
  }
  .search-icon {
    display: flex;
  }
}

@media only screen and (max-width: 510px) {
  .container-search-bar {
    flex-flow: wrap;
  }
}
</style>
