<template>
  <div class="google-settings__other">
    <BaseInput
      v-for="field in settingsStoreHelpers.GOOGLE_INPUT_FIELDS"
      :key="field.fieldName"
      :input-disabled="settingsStore.settingsLoading"
      :input-id="field.fieldName"
      :input-type="field.fieldType"
      v-model="settingsStore[field.fieldName]"
      :is-required="true"
      @unsaved-changes="settingsStore.unsavedChanges = true"
    />
  </div>
</template>

<script setup>
import BaseInput from "@/components/UI/BaseInput.vue";
import googleSettingsStore from "@/stores/settingsStore";
import settingsStoreHelpers from "@/stores/settingsStoreHelpers";

const settingsStore = googleSettingsStore();
</script>

<style lang="scss" scoped>
.pl-2 {
  padding-left: 20px;
}
.google-settings__other {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
