<template>
  <!-- if widget mode is false -->
  <template v-if="!props.widgetMode">
    <nav-bar></nav-bar>
    <title-bar :title="currentTitle"></title-bar>
    <div
      :class="{
        'menu-tab': ['/google-feed', '/products'].includes(route.path),
      }"
    >
      <router-view @get-title="handleTabTitle"></router-view>
    </div>
  </template>
  <!-- if widget mode is true -->
  <template v-else>
    <div
      :class="{
        'menu-tab': ['/google-feed', '/products'].includes(route.path),
      }"
    >
      <router-view :widgetData="widgetData"></router-view>
    </div>
  </template>
</template>

<script setup>
import NavBar from "@/components/GeneralComponents/Navbar/NavBar.vue";
import TitleBar from "@/components/GeneralComponents/TitleBar/TitleBar.vue";

import { ref, defineProps } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const currentTitle = ref("");
const handleTabTitle = (tabTitle) => {
  currentTitle.value = tabTitle;
};
const props = defineProps(["widgetMode", "widgetData"]);
</script>

<style>
.menu-tab {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;

  position: relative;
}
</style>
