import { defineStore } from "pinia";
import helpers from "@/services/helpers";

import googleSettingsStore from "./settingsStore";
import toastStore from "./toastStore";

const googleLoginStore = defineStore("googleLoginStore", {
  state: () => {
    return {
      //google login
      isUserLoggedIn: false,
      timer: null,
      counter: 0,
      //mechant id
      merchantIdsArray: [],
      selectedMerchantId: undefined,
      merchantIdExist: false,
      noMerchantAccountError: false,
      emptyInputMerchantIdError: false,
      loading: false,
      //sync status
      syncStatus: null,
      //settings tab form states
      errorForm: false,
      loadingForm: false,
      unsavedChangesForm: false,
      // initialize other
      settingsStore: googleSettingsStore(),
      notification: toastStore(),
    };
  },
  actions: {
    /************************************ Google Login actions **************************************/
    /********************************/
    // This functions only change the state in the app not in the database
    localLogin() {
      this.isUserLoggedIn = true;
    },
    localLogout() {
      this.isUserLoggedIn = false;
    },
    checkIfUserIsloggedIn() {
      this.timer = setInterval(() => {
        this.counter += 3000;
        helpers
          .sendRequest("api/setting/check-logged-in", "get")
          .then((data) => {
            if (this.counter > 120000) {
              clearInterval(this.timer);
              this.counter = 0;
            }
            if (data.logged === 1) {
              this.localLogin();
              clearInterval(this.timer);
              this.counter = 0;
            }
          });
      }, 3000);
    },
    signInGoogle(updateMode = false) {
      // run checking login function
      // setting update mode to true will skip the check
      if (!updateMode) {
        this.checkIfUserIsloggedIn();
      } else {
        this.checkMerchantArrayExistSetInterval();
      }
      // redirect user to google login in new window
      helpers.sendRequest("google/redirect", "get").then((data) => {
        window.open(data.url);
      });
    },
    /************************************ Merchant Id actions **************************************/
    /**************************************************************************/
    setSelectedMerchantId(value) {
      this.selectedMerchantId = value;
    },
    // method to check if the merchant id is setted in database
    checkMerchantIdExist() {
      this.loading = true;
      helpers
        .sendRequest("api/setting/check-merchant-id", "get")
        .then((data) => {
          if (data.status) {
            this.merchantIdExist = true;
            // check if user has saved default setting for the first time
            this.settingsStore.isUserSavedSettingsHandler();
          } else {
            this.merchantIdExist = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          if (
            err.response.data.message ===
            "No valid merchant ID found. Please enter the correct merchant ID."
          ) {
            this.loading = false;
          }
        });
    },
    checkMerchantArrayExistSetInterval() {
      this.timer = setInterval(() => {
        this.counter += 3000;
        helpers
          .sendRequest("api/setting/choose-merchant-id", "get")
          .then((data) => {
            if (data) {
              this.getMerchantIds();
            }
          })
          .catch(() => {
            if (this.counter > 120000) {
              clearInterval(this.timer);
              this.counter = 0;
            }
          });
      }, 3000);
    },
    // this method send a request to get the available metchant ids
    getMerchantIds() {
      helpers
        .sendRequest("api/setting/choose-merchant-id", "get")
        .then((data) => {
          this.noMerchantAccountError = false;
          this.merchantIdsArray = data.merchant_ids.map((id) => {
            return { id };
          });
        })
        // if the user account has no merchant account handle this error
        .catch((error) => {
          if (error.response.data.error === "no merchant ids to select from") {
            this.noMerchantAccountError = true;
          }
        });
    },
    // this method save the selected merchant id with post request
    saveMerchantId() {
      if (this.selectedMerchantId === undefined) {
        this.emptyInputMerchantIdError = true;
        return;
      }
      this.emptyInputMerchantIdError = false;

      console.log(this.selectedMerchantId);

      helpers
        .sendRequest("api/setting/store-settings", "post", {
          merchant_id: this.selectedMerchantId.id
            ? +this.selectedMerchantId.id
            : +this.selectedMerchantId,
        })
        .then(() => {
          this.merchantIdExist = true;
        });
    },
    /************************************ Sync Status actions **************************************/
    /**************************************************************************/
    async getSyncStatus() {
      try {
        this.loadingForm = true;
        this.errorForm = false;
        const data = await helpers.sendRequest(
          "api/setting/get-sync-status",
          "get"
        );
        if (data.status === "success") {
          this.syncStatus = Boolean(data.data.sync_status);
          this.selectedMerchantId = this.settingsStore.merchant_id.toString();

          this.loadingForm = false;
          this.unsavedChangesForm = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingForm = false;
        this.unsavedChangesForm = false;
        this.errorForm = true;
        this.notification.showNotification(
          "error",
          "Error",
          "Error fetching data, please try agian later!",
          1500
        );
      }
    },
    async editSyncStatus(newStatus) {
      //udpate syncStatus with the new value
      return await helpers.sendRequest(
        "api/setting/update-sync-status",
        "put",
        {
          sync_status: newStatus,
        }
      );
    },
    async submitForm(newStatus) {
      if (this.unsavedChangesForm) {
        try {
          this.loadingForm = true;
          this.errorForm = false;
          this.saveMerchantId();
          const data = await this.editSyncStatus(newStatus);
          if (data.status === "success") {
            this.syncStatus = newStatus;
            this.loadingForm = false;
            this.unsavedChangesForm = false;
            this.notification.showNotification(
              "success",
              "Success",
              "Data has been saved succefully!",
              1500
            );
          }
        } catch (error) {
          this.loadingForm = false;
          this.unsavedChangesForm = false;
          this.errorForm = true;
          this.notification.showNotification(
            "error",
            "Error",
            "Error sending data, please try agian later!",
            1500
          );
        }
      } else {
        this.notification.showNotification(
          "info",
          "All changes upto date!",
          "There is no changes to be saved!",
          1500
        );
      }
    },
  },
});

export default googleLoginStore;
