<template>
  <section class="wdgt" v-if="wdgetsStatus">
    <!-- if plugin is installed show content of the widget -->
    <div class="sub-header">
      <p>Product Id</p>
      <p class="product-id">{{ objectProductWidget.product_id }}</p>
      <p>Variant Id</p>
      <p class="variant-id">{{ objectProductWidget.variant_id }}</p>
      <p>Last Updated</p>
      <p class="updated-at">{{ updatedAt }}</p>
      <p>Product Status</p>
      <p class="product-status">{{ googleStatus }}</p>
    </div>
    <div>
      <template v-if="Object.keys(googleErrorJSON).length !== 0">
        <p>Errors Details</p>
        <vue-json-pretty
          class="json-container"
          :data="googleErrorJSON"
          :showIcon="true"
          :show-double-quotes="false"
          :showLine="false"
        />
      </template>

      <p v-else-if="googleStatus === 'error'">Empty Error Data.</p>
    </div>
  </section>
  <section class="install" v-else>
    <h3>please Install your waitlist app</h3>
    <a href="https://admin.lexmodo.com/plugins/google-feed">Install now</a>
  </section>
</template>

<script>
import helpers from "@/services/helpers";
import "vue-json-pretty/lib/styles.css";
import VueJsonPretty from "vue-json-pretty";

export default {
  data() {
    return {
      selectedCustomers: [],
      waitlistData: [],
      objectProductWidget: {},
      wdgetsStatus: true,
      googleStatus: "NA",
      googleErrorJSON: {},
      updatedAt: "",
    };
  },
  name: "WidgetApp",
  components: { VueJsonPretty },
  props: ["widgetData"],
  mounted() {
    this.objectProductWidget = this.widgetData.product_widget;
    this.wdgetsStatus = this.widgetData.product_widget.product_widget;

    document.body.style.display = "inline";
    document.getElementById("body")?.classList.remove("d-none");
    this.getWidgetData();
  },
  methods: {
    // devadmin: &widget=1&pid=4Z7CGuCMdGztgZDeZtYEEZ&vid=edd1df8c-2a3f-55b9-a6cb-e4b76716eaa2
    getWidgetData() {
      helpers
        .sendRequest(
          `api/widget-data?product_id=${this.objectProductWidget.product_id}&variant_id=${this.objectProductWidget.variant_id}`,
          "get"
        )
        .then((data) => {
          this.googleStatus = data.data.status;
          this.googleErrorJSON = data.data.errors;
          this.updatedAt = helpers.formatTimeStamp(data.data.updated_at);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
section.wdgt {
  background-color: #d4e5ff;
  padding: 40px 30px 120px 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #202223;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.sub-header {
  margin-bottom: 28px;
}
.product-id,
.product-status,
.variant-id,
.updated-at {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #1166a8;
}
.product-status {
  text-transform: capitalize;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  color: #202223;
}

.install {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    font-size: 21px;
    text-transform: capitalize;
  }
  a {
    background-color: #025ca1;
    color: #fff;
    text-transform: capitalize;
    font-size: 16px;
    border-radius: 5px;
    padding: 9px 15px;
    margin-top: 21px;
  }
}
/* customize color of the errors json object */
:deep(.json-container span.vjs-value-string) {
  color: red !important;
}
</style>
