<template>
  <div class="overlay">
    <div class="modal">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
:deep(iframe body) {
  overflow: hidden;
}
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.35);
}
.modal {
  height: 230px;
  width: 380px;
  background-color: #fff;
  z-index: 3;
  border: 1px solid #aaa;
  border-radius: 10px;
  overflow: hidden;
}
</style>
