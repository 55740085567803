<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.799805" width="40" height="40" rx="8" fill="#FD7575" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.4837 7.02878C22.112 5.65707 19.888 5.65707 18.5163 7.02878L8.02878 17.5163C6.65707 18.888 6.65707 21.112 8.02878 22.4837L18.5163 32.9712C19.888 34.3429 22.112 34.3429 23.4837 32.9712L33.9712 22.4837C35.3429 21.112 35.3429 18.888 33.9712 17.5163L23.4837 7.02878ZM19.5949 14.38C19.5949 13.6041 20.224 12.975 20.9999 12.975C21.7759 12.975 22.4049 13.6041 22.4049 14.38V20C22.4049 20.776 21.7759 21.405 20.9999 21.405C20.224 21.405 19.5949 20.776 19.5949 20V14.38ZM22.4049 25.62C22.4049 26.3959 21.7759 27.025 20.9999 27.025C20.224 27.025 19.5949 26.3959 19.5949 25.62C19.5949 24.844 20.224 24.215 20.9999 24.215C21.7759 24.215 22.4049 24.844 22.4049 25.62Z"
      fill="white"
    />
  </svg>
</template>
