<template>
  <WrapperCard style="margin-top: 20px">
    <div class="settings">
      <heading-five> Over Sized Products Settings </heading-five>
      <BaseInput
        v-for="field in ['length', 'width', 'height']"
        :key="field"
        :input-disabled="settingsStore.settingsLoading"
        :input-id="field"
        input-type="number"
        v-model.number="settingsStore[field]"
        @unsaved-changes="settingsStore.unsavedChanges = true"
      />
      <div class="label-input">
        <label for="use_settings">Use Settings</label>

        <ToggleButton
          v-model="settingsStore.use_settings"
          :disabled="settingsStore.settingsLoading"
          input-id="use_settings"
          class="w-6rem"
          on-label="ON"
          off-label="OFF"
          @change="settingsStore.unsavedChanges = true"
        />
      </div>
    </div>
  </WrapperCard>
</template>

<script setup>
import BaseInput from "@/components/UI/BaseInput.vue";

import HeadingFive from "@/components/UI/Headings/HeadingFive";
import WrapperCard from "@/components/UI/WrapperCard.vue";
import googleSettingsStore from "@/stores/settingsStore";
import ToggleButton from "primevue/togglebutton";

/********************* variables and state define *********************/
const settingsStore = googleSettingsStore();
</script>

<style scoped lang="scss">
.settings {
  padding: 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.label-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 1200px) {
  form.settings {
    max-width: 75%;
  }
}
@media only screen and (max-width: 1200px) {
  form.settings {
    max-width: 60%;
  }
}
@media only screen and (max-width: 1000px) {
  form.settings {
    max-width: 75%;
  }
}
@media only screen and (max-width: 800px) {
  form.settings {
    max-width: 100%;
  }
}
</style>
