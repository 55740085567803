<template>
  <form @submit.prevent="syncConfirmMessageHandler">
    <WrapperCard class="google-settings">
      <div style="display: flex; justify-content: space-between">
        <heading-five> Google Settings </heading-five>
        <warning-message v-if="settingsStore.unsavedChanges"
          >Unsaved Changes!</warning-message
        >
      </div>
      <GoogleIdFields></GoogleIdFields>
      <heading-five> Other Settings </heading-five>
      <GoogleOtherFields />
    </WrapperCard>

    <OversizedSettings v-if="!firstTimeSubmitting" />
    <ActionsContainer :firstTimeSubmitting="Boolean(firstTimeSubmitting)" />
  </form>

  <ModalOverlay v-if="leavePageConfirmationShow">
    <ConfirmMessage @confirm-message="leaveConfirmMessageEvent">
      <template #title>Unsaved Changes</template>
      <template #body>
        If you leave this page, any unsaved changes will be lost
      </template>
      <template #reject-btn> Cancel </template>
      <template #accept-btn> Leave Page </template>
    </ConfirmMessage>
  </ModalOverlay>

  <ModalOverlay v-if="syncCheckConfirmationShow">
    <ConfirmMessage @confirm-message="syncConfirmMessageResponseHandler">
      <template #title>Sync Products</template>
      <template #body>
        Do you want to sync products now to google?, you can update this from
        settings anytime to start sync to Google.
      </template>
      <template #reject-btn> Cancel </template>
      <template #accept-btn> Sync Products </template>
    </ConfirmMessage>
  </ModalOverlay>
</template>

<script setup>
import GoogleIdFields from "@/components/AdvancedSettingsComponents/GoogleIdFields";
import GoogleOtherFields from "@/components/AdvancedSettingsComponents/GoogleOtherFields";
import OversizedSettings from "@/components/AdvancedSettingsComponents/OversizedSettings.vue";
import ActionsContainer from "@/components/AdvancedSettingsComponents/ActionsContainer.vue";
import ModalOverlay from "@/components/UI/ModalOverlay.vue";
import ConfirmMessage from "@/components/UI/ConfirmMessage.vue";
import WrapperCard from "@/components/UI/WrapperCard.vue";
import HeadingFive from "@/components/UI/Headings/HeadingFive";
import WarningMessage from "@/components/UI/WarningMessage.vue";

import { onMounted, defineEmits, defineProps, ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

import googleLoginStore from "@/stores/googleLoginStore";
import googleSettingsStore from "@/stores/settingsStore";
import toastStore from "@/stores/toastStore";
import router from "@/router";

// emits, props, variables
const emit = defineEmits(["getTitle"]);
const props = defineProps(["firstTimeSubmitting"]);

const loginStore = googleLoginStore();
const settingsStore = googleSettingsStore();
const notifications = toastStore();

const leavingToRoute = ref("");
const leavePageConfirmationShow = ref(false);
const syncCheckConfirmationShow = ref(false);

//Functions and Methods
const leaveConfirmMessageEvent = (proceed) => {
  leavePageConfirmationShow.value = false;
  if (proceed) {
    settingsStore.getUserSettings();
    settingsStore.unsavedChanges = false;
    router.push(leavingToRoute.value);
  } else {
    leavingToRoute.value = "";
  }
};

let formEvent;
const syncConfirmMessageHandler = (event) => {
  if (props.firstTimeSubmitting) {
    syncCheckConfirmationShow.value = true;
    formEvent = event;
  } else {
    settingsSubmitHandler(event);
  }
};

const syncConfirmMessageResponseHandler = (userResponse) => {
  loginStore.editSyncStatus(userResponse);
  //hide dialog
  syncCheckConfirmationShow.value = false;
  //submit settings
  settingsStore.unsavedChanges = true;
  settingsSubmitHandler(formEvent);
};

const settingsSubmitHandler = (event) => {
  const formElements = event.target.elements;
  settingsStore.invalidForm = false;

  //checking form validty
  // validate custom field added texts also "rule_query", "shipping_value", "currency"
  for (const element in formElements) {
    const formElement = formElements[element];
    if (
      (formElement.nodeName === "INPUT" &&
        !formElement.disabled &&
        formElements[element].classList.contains("p-invalid")) ||
      (formElement.nodeName === "INPUT" &&
        [
          "rule_query",
          "shipping_value",
          "currency",
          "length",
          "width",
          "height",
        ].includes(formElements[element].id) &&
        formElements[element].value === "")
    ) {
      formElements[element].focus();
      settingsStore.invalidForm = true;
      notifications.showNotification(
        "warn",
        "Invalid Inputs",
        "Please check entered data!",
        1500
      );
      break;
    }
  }

  // validate custom field values
  settingsStore.idFieldsData.forEach((field) => {
    if (field.selectedType === "") {
      settingsStore.invalidForm = true;
      notifications.showNotification(
        "warn",
        "Empty property value",
        `${field.fieldName} must has a value!`,
        1500
      );
    }
  });
  if (!settingsStore.invalidForm && settingsStore.unsavedChanges) {
    settingsStore
      .submitSettings(props.firstTimeSubmitting ? true : false)
      .then(() => {
        if (!settingsStore.settingsError)
          notifications.showNotification(
            "success",
            "Settings Saved",
            "settings have been saved succefully!",
            1500
          );
      });
  } else if (!settingsStore.invalidForm && !settingsStore.unsavedChanges) {
    notifications.showNotification(
      "info",
      "All changes upto date!",
      "There is no changes to be saved!",
      1500
    );
  }
};

// Lifecycle hooks
onMounted(() => {
  if (settingsStore.idFieldsData.length === 0) {
    settingsStore.getUserSettings();
  }
  emit("getTitle", "Mapping Settings");
});

if (router.currentRoute.value.path === "/mapping-settings") {
  onBeforeRouteLeave((to, _from, next) => {
    if (!settingsStore.unsavedChanges) {
      next(true);
    } else {
      leavePageConfirmationShow.value = true;
      leavingToRoute.value = to;
      next(false);
    }
  });
}
</script>

<style scoped lang="scss">
.google-settings {
  padding: 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.pl-2 {
  padding-left: 20px;
}
.google-settings__other {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.actions-container {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  margin-top: 20px;
}
</style>
