<template>
  <DataTable
    dataKey="variant_id"
    :global-filter-fields="[
      'variant_id',
      'product_id',
      'product_name',
      'status',
      'is_excluded',
    ]"
    v-model:selection="selectedProducts"
    class="datatable"
    :value="productsData"
    :rows="10"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    responsiveLayout="scroll"
    lazy
    scrollable
    :paginator="productsData.length !== 0"
    scroll-height="600px"
    :loading="loading"
    :totalRecords="total"
    removableSort
    @page="onPage($event)"
    @sort="onSort($event)"
    @filter="onPage($event)"
  >
    <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
    <Column
      sortable
      field="product_name"
      header="Product Name"
      style="min-width: 400px"
      :filter="true"
    >
      <template #body="{ data }">
        <div class="product_container">
          <img
            v-if="data.product_image"
            class="product_image"
            :src="helpers.formatImageLink(data.product_image)"
            alt="product image"
          />
          <div v-else class="product_image"></div>
          <div>
            <p>{{ data.product_name }}</p>
            <a
              target="_blank"
              class="product_link"
              :href="`https://admin.lexmodo.com/products/${data.product_id}/variants/${data.variant_id}`"
              >{{ data.variant_id }}</a
            >
          </div>
        </div>
      </template>
    </Column>

    <Column field="status" header="Product Status" style="min-width: 400px">
      <template #body="{ data }">
        <div v-if="data.status === 'error'" class="errors_cell">
          <ErrorSvg style="min-width: 16px; height: 16px" />
          <span> Submitted with Error/s </span>
        </div>
        <div v-else-if="data.status === 'success'" class="errors_cell">
          <SubmittedSvg />
          <span>Submitted Successfully</span>
        </div>
      </template>
    </Column>

    <Column
      field="google_error_array"
      header="Google Errors"
      style="min-width: 100px"
    >
      <template #body="{ data }">
        <button
          v-if="data.status === 'error'"
          @click="showErrorsModal(data.google_error_array, data.variant_id)"
          class="show-btn"
        >
          <EyeIconSvg />
          <span>Show</span>
        </button>
        <span v-else> No Errors </span>
      </template></Column
    >

    <Column
      field="updated_at"
      header="Updated At"
      style="min-width: 190px"
      sortable
    >
      <template #body="{ data }">
        {{ helpers.formatTimeStamp(data.updated_at) }}
      </template>
    </Column>

    <template #empty>
      <empty-data :tabName="tabName"></empty-data>
    </template>
    <template #loading>
      <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
    </template>
  </DataTable>
  <div class="deleteBar" v-if="selectedProducts.length > 0">
    <button @click="excludeIncludeSelected(tabName !== 'excluded-products')">
      <span v-if="tabName !== 'excluded-products'">Exclude</span>
      <span v-else>Include</span> Selected
    </button>
    <button @click="selectedProducts = []" class="flat">Clear Selection</button>
    <p>{{ selectedProducts?.length }} selected</p>
  </div>
  <ShowDetailsModal
    v-if="errorsModelVisible"
    :variant-id="currentVariantId"
    :errors-data="currentErrorData"
    @hide-modal="hideErrorsModal"
  ></ShowDetailsModal>
</template>
<script>
// js imports
import emitter from "@/services/eventBus";
import helpers from "@/services/helpers";
// components imports
import ShowDetailsModal from "./SubComponents/ShowDetailsModal.vue";
import EmptyData from "../GeneralComponents/EmptyData.vue";
// svg imports
import EyeIconSvg from "./Icons/EyeIconSvg.vue";
import SubmittedSvg from "./Icons/SubmittedSvg.vue";
import ErrorSvg from "./Icons/ErrorSvg.vue";
import countersStore from "@/stores/countersStore";

export default {
  mounted() {
    // get table data
    this.loadLazyData();

    // Emit Listeners:
    emitter.on("refresh-table-data", () => {
      //Call loadLazyData
      this.loadLazyData();
    });
    emitter.on("filter-table-data", (filterCriteria) => {
      // Update filter object
      this.filters.product_id.value = filterCriteria.product_id;
      this.filters.product_name.value = filterCriteria.product_name;
      this.filters.variant_id.value = filterCriteria.variant_id;
      // Call loadLazyData
      this.loadLazyData();
    });
    emitter.on("clear-table-filters", () => {
      // Clear filter object
      this.resetFilters();
      // Call loadLazyData
      this.loadLazyData();
    });
  },
  watch: {
    // if tabname changed clear filters and load data again for the new clicked tab
    tabName() {
      this.resetFilters();
      this.loadLazyData();
    },
  },
  methods: {
    showErrorsModal(errorsData = {}, variantId) {
      this.currentErrorData = errorsData;
      this.currentVariantId = variantId;
      this.errorsModelVisible = true;
    },
    hideErrorsModal() {
      this.errorsModelVisible = false;
    },
    excludeIncludeSelected(isExcluded) {
      this.selectedProducts.forEach((product, index) => {
        const sentObject = {
          is_excluded: isExcluded ? 1 : 0,
          variant_id: product.variant_id,
          product_id: product.product_id,
        };
        helpers
          .sendRequest("api/update-exclude-status", "post", sentObject)
          .then((data) => {
            if (data.message === "success") {
              // if this is the last element to be excluded clear selection and reload data
              if (index === this.selectedProducts.length - 1) {
                // update table data
                this.loadLazyData();
                // update counters
                this.appCounterStore.getCounters();
                // clear selection
                this.selectedProducts = [];
              }
            }
          });
      });
    },
    loadLazyData() {
      this.loading = true;
      this.lazyParams.filters = this.filters;
      // check which table data will be loaded and filter data
      if (this.tabName === "products-with-errors") {
        this.lazyParams.filters.status = {
          value: "error",
          matchMode: "equals",
        };
      } else if (this.tabName === "submitted-products") {
        this.lazyParams.filters.status = {
          value: "success",
          matchMode: "equals",
        };
      } else if (this.tabName === "excluded-products") {
        this.lazyParams.filters.is_excluded = {
          value: 1,
          matchMode: "equals",
        };
      }
      // send request
      helpers
        .sendRequest("api/all-products", "get", this.lazyParams)
        .then((data) => {
          this.loading = false;
          this.total = data.total;
          this.productsData = data.data;
        })
        .catch((error) => {
          // next lines should be removed when others routes are added
          // if status is 404 this means routes does not exists

          if (error.response.status === 404) {
            this.total = 0;
            this.loading = false;
            this.productsData = [];
          }
        });
    },
    onPage(data) {
      this.lazyParams = data;
      this.loadLazyData();
    },
    onSort(event) {
      this.lazyParams = event;
      this.lazyParams.filters = this.filters;
      this.loadLazyData();
    },
    resetFilters() {
      this.lazyParams.sortField = null;
      this.lazyParams.sortOrder = null;
      this.lazyParams = {
        first: 0,
        sortField: "product_name",
        sortOrder: 1,
        filters: {},
      };
      this.filters = {
        global: {
          value: "",
          matchMode: "contains",
        },
        variant_id: {
          value: "",
          matchMode: "contains",
        },
        product_id: {
          value: "",
          matchMode: "contains",
        },
        product_name: {
          value: "",
          matchMode: "contains",
        },
      };
    },
  },
  props: ["tabName"],
  components: {
    EmptyData,
    ErrorSvg,
    SubmittedSvg,
    ShowDetailsModal,
    EyeIconSvg,
  },
  data() {
    return {
      appCounterStore: countersStore(),
      helpers: helpers,
      selectedProducts: [],
      productsData: [],
      lazyParams: {
        first: 0,
        sortField: "product_name",
        sortOrder: 1,
        filters: {},
      },
      filters: {
        global: {
          value: "",
          matchMode: "contains",
        },
        variant_id: {
          value: "",
          matchMode: "contains",
        },
        product_id: {
          value: "",
          matchMode: "contains",
        },
        product_name: {
          value: "",
          matchMode: "contains",
        },
      },

      currentErrorData: {},
      currentVariantId: "",
      errorsModelVisible: false,
      total: 0,
      error: false,
      loading: false,
    };
  },
};
</script>
<style scoped lang="scss">
.datatable {
  //Style table font
  :deep(.p-column-title),
  :deep(.p-datatable-tbody td) {
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202223;
  }
  //Style table header and its font
  :deep(.p-datatable-thead > tr > th) {
    background-color: #fff;
    .p-column-title {
      font-weight: 500;
    }
  }
  // Style paginator and align it to the right
  :deep(div.p-paginator) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  :deep(button.p-paginator-prev),
  :deep(button.p-paginator-next),
  :deep(button.p-paginator-first),
  :deep(button.p-paginator-last) {
    color: #202223;
    width: 40px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
  }
  :deep(span.p-paginator-current) {
    cursor: default;
    font-weight: 400;
    font-size: 14px;
    color: #202223;
  }
}

//Style of product column
.product_container {
  display: flex;
  gap: 22px;
}

.product_image {
  object-fit: cover;
  flex-shrink: 0;
  width: 55px;
  height: 55px;
}

.product_link:link,
.product_link:visited {
  color: #1575bf;
}

.product_link:hover {
  color: #00477e;
}

.product_link::before {
  content: "•";
  margin-right: 10px;
  text-decoration: none !important;
}

//Style of Errors Column
.errors_header {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.errors_cell {
  display: flex;
  gap: 10px;
  align-items: center;
}

//Style the delete bar
.deleteBar {
  position: absolute;
  padding-left: 68px;
  top: 0;
  right: 0;
  width: 100%;
  height: 69px;
  display: flex;
  gap: 24px;
  align-items: center;
  background-color: #fff;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.deleteBar button {
  padding: 5.5px 21px;
  cursor: pointer;
  border: 1px solid #cecece;
  border-radius: 8px;
  outline: none;
  color: #c83e25;
}

.deleteBar button.flat {
  border: 1px solid #cecece;
  color: #fff;
  background-color: #c83e25;
}

.deleteBar button:hover {
  background-color: #f6f6f6;
}

.deleteBar button.flat:hover {
  background-color: #a12a18;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

//style show details column
.show-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
