<template>
  <div class="page-container">
    <!-- 
              - This page will be shown when the user is not logged in
              - it should contain a button that redirect user to a new window
              where user can authorize his google account
          -->
    <p>Please log in to your google account.</p>

    <base-button @click="store.signInGoogle(false)" class="login-btn">
      <GoogleLogo />
      <span>Login</span>
    </base-button>
  </div>
</template>

<script setup>
import GoogleLogo from "@/assets/Icons/GoogleLogo.vue";
import googleLoginStore from "@/stores/googleLoginStore";

const store = googleLoginStore();
// import helpers from "@/services/helpers";

// let counter = 0;

// This funciton is executed when button clicked
// function signInGoogle() {
//   checkIfUserIsloggedIn();
//   helpers.sendRequest("google/redirect", "get").then((data) => {
//     window.open(data.url);
//   });
// }
// This function send request every one second to check if user has logged in with google
// function checkIfUserIsloggedIn() {
//   const timer = setInterval(() => {
//     counter += 1000;
//     helpers.sendRequest("api/setting/check-logged-in", "get").then((data) => {
//       if (counter > 60000) {
//         clearTimeout(timer);
//       }
//       if (data.logged === 1) {
//         console.log(counter);
//         sessionStorage.removeItem("auth_google");
//         window.location.reload();
//       }
//     });
//   }, 1000);

//   return timer;
// }
</script>

<style scoped lang="scss">
div.page-container {
  padding: 32px;
  min-height: 50vh;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 10px;
  }
  .login-btn {
    display: flex;
    gap: 6px;
    justify-content: space-between;
  }
}
</style>
