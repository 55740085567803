<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8347_75566)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9423 7.70266C14.2327 3.42854 10.9447 1.48085 7.65821 1.65085C4.40956 1.81888 1.34442 4.05225 0.0451446 7.73352L-0.0488281 7.99978L0.0451446 8.26603C1.34442 11.9473 4.40956 14.1807 7.65821 14.3487C10.9447 14.5187 14.2327 12.571 15.9423 8.29689L16.0612 7.99978L15.9423 7.70266ZM7.99961 11.9998C10.2087 11.9998 11.9996 10.2089 11.9996 7.99978C11.9996 5.79064 10.2087 3.99978 7.99961 3.99978C5.79047 3.99978 3.99961 5.79064 3.99961 7.99978C3.99961 10.2089 5.79047 11.9998 7.99961 11.9998Z"
        fill="#575959"
      />
      <path
        d="M10.3996 7.99978C10.3996 9.32526 9.32509 10.3998 7.99961 10.3998C6.67413 10.3998 5.59961 9.32526 5.59961 7.99978C5.59961 6.6743 6.67413 5.59978 7.99961 5.59978C9.32509 5.59978 10.3996 6.6743 10.3996 7.99978Z"
        fill="#575959"
      />
    </g>
    <defs>
      <clipPath id="clip0_8347_75566">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
