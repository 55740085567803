import { defineStore } from "pinia";

import toastStore from "./toastStore";
import settingsStoreHelpers from "./settingsStoreHelpers";
import helpers from "@/services/helpers";

// get helpers functions and variables from settingsStoreHelpers
const { removeAddedTextFromIdType } = settingsStoreHelpers;
const { extractAddedTextFromIdType } = settingsStoreHelpers;
const { ID_DROPDOWN_FIELDS } = settingsStoreHelpers;
const { ID_DROPDOWN_GOOGLE_FIELDS } = settingsStoreHelpers;
const { OTHER_DROPDOWN_FIELDS } = settingsStoreHelpers;
const { otherFieldsDataInitialValue } = settingsStoreHelpers;

const googleSettingsStore = defineStore("googleSettingsStore", {
  state: () => {
    return {
      merchant_id: null,
      settingsLoading: false,
      settingsError: false,
      isUserSavedSettings: false,
      userSettings: {},
      /**
       ** {fieldName: 'string', selectedType: 'variant_id', addedText: 'string'}
       ** If selectedType is custom then 'addedText' should has a value.
       */
      idFieldsData: [],
      idFieldsOptions: [],
      otherFieldsData: {
        ...otherFieldsDataInitialValue,
      },
      currency: "",
      shipping_value: "",
      rule_query: "",
      /**
       * - array that contains the other google id properties which are not added by default.
       * - so user could select a new property from this array to add.
       * - each id can be used once.
       */
      otherGooglePropertiesOptions: [],
      /**
       * - array that contains the propeties that are not selected by user yet.
       * - when user select a property it will be removed from this array
       * - this array should be same as otherGooglePropertiesOptions if user didn't add any properties yet
       */
      unusedOtherGooglePropertiesOptions: [],
      unsavedChanges: false,
      currentSettingsIsDefault: false,
      invalidForm: false,
      notifications: toastStore(),
      overSizedSettingsDefaults: null,
      length: null,
      width: null,
      height: null,
      use_settings: null,
    };
  },
  actions: {
    /**
     * This function should add a new field selected from google fields
     * Hence remove this field from unusedOtherGooglePropertiesOptions
     * @param {String} fieldName
     */
    addIdField(fieldName) {
      this.unsavedChanges = true;
      this.idFieldsData.push({
        fieldName: fieldName,
        selectedType: "",
        addedText: "",
        removable: true,
      });
      this.unusedOtherGooglePropertiesOptions =
        this.unusedOtherGooglePropertiesOptions.filter(
          (value) => value !== fieldName
        );
    },
    removeField(fieldName) {
      this.unsavedChanges = true;
      this.idFieldsData = this.idFieldsData.filter(
        (field) => field.fieldName !== fieldName
      );
      this.unusedOtherGooglePropertiesOptions.push(fieldName);
    },
    /**
     * Function that updates id fields data.
     * @param {String} fieldName
     * @param {Object} newData
     */
    updateIdFieldsData(fieldName, newData) {
      this.unsavedChanges = true;
      const fieldIndex = this.idFieldsData.findIndex(
        (field) => field.fieldName === fieldName
      );
      if (fieldIndex !== -1) {
        const removable = this.idFieldsData[fieldIndex].removable;
        this.idFieldsData[fieldIndex] = { ...newData, removable };
      }
    },
    /**
     * Function that updates fields data that are not id fields
     * @param {String} fieldName
     * @param {String} value
     */
    updateOtherFieldsData(fieldName, value) {
      this.unsavedChanges = true;
      this.otherFieldsData[fieldName].value = value;
    },
    async isUserSavedSettingsHandler() {
      this.settingsLoading = true;
      const data = await helpers.sendRequest(
        "api/setting/check-saved-default",
        "get"
      );
      const { status } = data;
      this.isUserSavedSettings = !!status;
      this.settingsLoading = false;
    },
    async getUserSettings(resetSettings = false) {
      try {
        this.settingsLoading = true;
        this.settingsError = false;

        const data = await helpers.sendRequest(
          "api/setting/show-settings",
          "get"
        );

        const {
          mapping_settings_properties,
          mapping_settings_defaults,
          mapping_settings_selected,
          over_sized_products_options,
          over_sized_products_options_default,
          shipping_value,
          currency,
          rule_query,
          merchant_id,
        } = data.data[0];

        const {
          lexmodo_data,
          google_data,
          accepted_values: { dimension_unit, weight_unit },
        } = JSON.parse(mapping_settings_properties);

        this.overSizedSettingsDefaults = JSON.parse(
          over_sized_products_options_default
        );

        // current settings is default if user is opening settings for the first time (no selected mapping setting)
        // also current settings is default if user is resetting his settings to defaults
        this.currentSettingsIsDefault =
          mapping_settings_selected === null || resetSettings;

        if (this.currentSettingsIsDefault) {
          this.userSettings = JSON.parse(mapping_settings_defaults);
          this.currency = this.userSettings.other_settings.currency;
          this.rule_query = this.userSettings.other_settings.rule_query;
          this.shipping_value = this.userSettings.other_settings.shipping_value;
          this.length = this.overSizedSettingsDefaults.length;
          this.width = this.overSizedSettingsDefaults.width;
          this.height = this.overSizedSettingsDefaults.height;
          this.use_settings = this.overSizedSettingsDefaults.use_settings;
        } else {
          this.userSettings = JSON.parse(mapping_settings_selected);
          this.currency = String(currency) || "";
          this.rule_query = rule_query;
          this.shipping_value = String(shipping_value) || "";
          this.length = JSON.parse(over_sized_products_options).length;
          this.width = JSON.parse(over_sized_products_options).width;
          this.height = JSON.parse(over_sized_products_options).height;
          this.use_settings = JSON.parse(
            over_sized_products_options
          ).use_settings;
        }

        this.otherGooglePropertiesOptions = lexmodo_data;
        this.unusedOtherGooglePropertiesOptions = google_data;
        this.otherFieldsData.dimension_unit.options = dimension_unit;
        this.otherFieldsData.weight_unit.options = weight_unit;

        this.merchant_id = merchant_id;
        this.idFieldsData = [];

        for (const [key, value] of Object.entries(this.userSettings)) {
          if (OTHER_DROPDOWN_FIELDS.includes(key)) {
            this.otherFieldsData[key].value = value;
          } else if (
            ID_DROPDOWN_FIELDS.includes(key) ||
            ID_DROPDOWN_GOOGLE_FIELDS.includes(key)
          ) {
            const removable = ID_DROPDOWN_GOOGLE_FIELDS.includes(key);
            this.idFieldsData.push({
              fieldName: key,
              selectedType: removeAddedTextFromIdType(value),
              addedText: extractAddedTextFromIdType(value),
              removable,
            });

            if (this.unusedOtherGooglePropertiesOptions.includes(key)) {
              this.unusedOtherGooglePropertiesOptions =
                this.unusedOtherGooglePropertiesOptions.filter(
                  (unusedKey) => unusedKey !== key
                );
            }
          }
        }

        // console.log(this.idFieldsData);
        this.settingsLoading = false;
        this.unsavedChanges = false;
      } catch (error) {
        console.log(error);
        this.settingsError = true;
        this.settingsLoading = false;
        this.notifications.showNotification(
          "error",
          "Error",
          "Error fetching data, please try agian later!",
          1500
        );
      }
    },
    async submitSettings(firstTimeSubmitting = false) {
      const fieldsDataSent = {};
      this.settingsLoading = true;
      this.settingsError = false;

      this.idFieldsData.forEach((field) => {
        fieldsDataSent[field.fieldName] =
          field.selectedType + (field.addedText ? `_${field.addedText}` : "");
      });

      for (const key in this.otherFieldsData) {
        fieldsDataSent[key] = this.otherFieldsData[key].value;
      }

      const sentMappingSettingsData = {
        ...fieldsDataSent,
      };

      const sentOverSizedSettingsData = {
        width: this.width,
        height: this.height,
        length: this.length,
        use_settings: this.use_settings,
      };

      let url = `api/setting/update-settings?merchant_id=${this.merchant_id}`,
        method = "put";
      if (firstTimeSubmitting)
        (url = `api/setting/store-settings?merchant_id=${this.merchant_id}`),
          (method = "post");

      try {
        await helpers.sendRequest(url, method, {
          mapping_settings_selected: sentMappingSettingsData,
          currency: this.currency,
          shipping_value: +this.shipping_value,
          rule_query: this.rule_query,
          over_sized_products_options: sentOverSizedSettingsData,
        });

        this.settingsLoading = false;
        this.isUserSavedSettings = true;
        this.unsavedChanges = false;
      } catch (error) {
        // error should be handled to show some notification to user
        this.settingsLoading = false;
        this.settingsError = true;

        this.notifications.showNotification(
          "error",
          "Error",
          "Error sending data, please try agian later!",
          1500
        );
      }
    },
  },
});

export default googleSettingsStore;
