import { defineStore } from "pinia";
import { useToast } from "primevue/usetoast";

const toastStore = defineStore("toastStore", {
  state: () => {
    return {
      toast: useToast(),
    };
  },
  actions: {
    /**
     *
     * @param {String} type "success", "error", "info", "warning".
     * @param {String} title The title of notification.
     * @param {String} message The message of notification.
     * @param {Number} timeDisplay The time of notification display.
     *
     */
    showNotification(type, title, message, timeDisplay) {
      this.toast.add({
        severity: type,
        summary: title,
        detail: message,
        life: timeDisplay,
      });
    },
  },
});

export default toastStore;
