<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.41385 7.99985L15.7069 1.70685C15.8944 1.51934 15.9997 1.26503 15.9997 0.999849C15.9997 0.734673 15.8944 0.480357 15.7069 0.292849C15.5193 0.105341 15.265 0 14.9998 0C14.7347 0 14.4804 0.105341 14.2928 0.292849L7.99985 6.58585L1.70685 0.292849C1.614 0.200005 1.50378 0.126356 1.38247 0.0761092C1.26117 0.0258621 1.13115 0 0.999849 0C0.868547 0 0.738531 0.0258621 0.617224 0.0761092C0.495916 0.126356 0.385694 0.200005 0.292849 0.292849C0.105341 0.480357 0 0.734673 0 0.999849C0 1.26503 0.105341 1.51934 0.292849 1.70685L6.58585 7.99985L0.292849 14.2928C0.105341 14.4804 0 14.7347 0 14.9998C0 15.265 0.105341 15.5193 0.292849 15.7069C0.480357 15.8944 0.734673 15.9997 0.999849 15.9997C1.26503 15.9997 1.51934 15.8944 1.70685 15.7069L7.99985 9.41385L14.2928 15.7069C14.3855 15.8 14.4957 15.874 14.617 15.9244C14.7383 15.9749 14.8684 16.0009 14.9998 16.0009C15.1313 16.0009 15.2614 15.9749 15.3827 15.9244C15.504 15.874 15.6142 15.8 15.7069 15.7069C15.7998 15.6141 15.8735 15.5039 15.9238 15.3825C15.9742 15.2612 16 15.1312 16 14.9998C16 14.8685 15.9742 14.7385 15.9238 14.6172C15.8735 14.4958 15.7998 14.3856 15.7069 14.2928L9.41385 7.99985Z"
      fill="#575959"
    />
  </svg>
</template>
