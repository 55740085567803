<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.200195" width="40" height="40" rx="8" fill="#41CD79" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.375 11.875C18.375 10.978 19.103 10.25 20 10.25C20.897 10.25 21.625 10.978 21.625 11.875H18.375ZM15.125 11.875C15.125 9.18238 17.3074 7 20 7C22.6926 7 24.875 9.18238 24.875 11.875H31.375C32.272 11.875 33 12.603 33 13.5C33 14.397 32.272 15.125 31.375 15.125H29.75V28.125C29.75 30.8176 27.5676 33 24.875 33H15.125C12.4324 33 10.25 30.8176 10.25 28.125V15.125H8.625C7.728 15.125 7 14.397 7 13.5C7 12.603 7.728 11.875 8.625 11.875H15.125Z"
      fill="white"
    />
  </svg>
</template>
