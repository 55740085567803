<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.card {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;

  position: relative;
}
</style>
