<template>
  <div class="backdrop">
    <div class="popup-container">
      <div class="header">
        <h5>Product Google Errors Details</h5>
        <CloseSignSvg @click.prevent="$emit('hideModal')" />
      </div>
      <div class="sub-header">
        <p>Variant Id</p>
        <p class="trans-id">{{ variantId }}</p>
      </div>
      <vue-json-pretty
        v-if="errorsData !== null"
        class="json-container"
        :data="errorsData"
        :showIcon="true"
        :show-double-quotes="false"
        :showLine="false"
      />
      <p v-else>Empty Error Data.</p>
      <div class="actions">
        <base-button @click.prevent="$emit('hideModal')" class="small"
          >Close</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import CloseSignSvg from "../Icons/CloseSignSvg.vue";
import "vue-json-pretty/lib/styles.css";
import VueJsonPretty from "vue-json-pretty";

export default {
  emits: ["hideModal"],
  components: { CloseSignSvg, VueJsonPretty },
  props: ["errorsData", "variantId"],
};
</script>

<style scoped>
.backdrop {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.35);
}

.popup-container {
  padding: 26px 30px;
  margin: 2.5% 20% 10% auto;
  background-color: #fff;
  width: 1017px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1005;

  position: fixed;
  top: 50px; /* replace 50px with the amount of top padding you want to keep */
  left: 50%;
  transform: translateX(-50%);
  overflow-y: scroll;
  height: 80%;
}

.popup-container input {
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding-left: 20px;
}

.popup-container input:focus {
  border: 2px solid #a8a8a8;
  outline: none;
}

.popup-container h5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 36px;
}

div.header svg {
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
}

.sub-header {
  color: #1166a8;
  margin-bottom: 28px;
}
.trans-id {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
}
.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
  column-gap: 22px;
}

.actions {
  padding-top: 57px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
}
/* customize color of the errors json object */
:deep(.json-container span.vjs-value-string) {
  color: red !important;
}
</style>
