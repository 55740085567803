<template>
  <div class="form-container">
    <h1 class="heading">Please select a property:</h1>
    <form class="form" @submit.prevent="submitFormHandler">
      <CustomDropdown
        key="property_name"
        inputName="property_name"
        :modelValue="selectedOption"
        :dropdownOptions="
          settingsStore.unusedOtherGooglePropertiesOptions.sort()
        "
        @update:modelValue="updateDropdownHandler"
      />

      <invalid-input-message
        :wrongTextMessage="invalidMessage"
      ></invalid-input-message>
      <div class="actions">
        <base-button type="submit">Confirm</base-button>
        <base-button type="button" @click="hideModalFn" class="flat"
          >Cancel</base-button
        >
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import CustomDropdown from "@/components/UI/CustomDropdown/CustomDropdown";
import googleSettingsStore from "@/stores/settingsStore";

const settingsStore = googleSettingsStore();

const props = defineProps(["hideModalFn"]);
const selectedOption = ref("");
const invalidMessage = ref("");

const submitFormHandler = () => {
  invalidMessage.value = "";
  if (!selectedOption.value) {
    console.log("select a value");
    invalidMessage.value = "Property name can't be empty.";
    return;
  }
  settingsStore.addIdField(selectedOption.value);
  props.hideModalFn();
};
const updateDropdownHandler = (event) => {
  invalidMessage.value = "";
  selectedOption.value = event;
};
</script>

<style scoped lang="scss">
.form-container {
  height: 100%;
  padding: 15px;
  .heading {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
  .form {
    height: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .actions {
    margin-top: auto;
    padding: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }
}
</style>
