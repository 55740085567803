<template>
  <div class="dropdown-id-contanier">
    <div class="label-input">
      <label>
        <a :href="labelLink" v-if="labelLink" target="_blank">
          {{ label }}
        </a>
        <template v-else>
          {{ label }}
        </template>
      </label>
      <Dropdown
        :filter="!!optionLabel"
        :filter-fields="[`${optionLabel}`]"
        :loading="dropdownLoading"
        v-model:modelValue="selectedOption"
        :options="dropdownOptions"
        @change="dropdownChangeHandler"
        style="width: 225px"
        :option-label="optionLabel && optionLabel"
        :option-value="optionValue && optionValue"
        :class="{ 'p-invalid': !modelValue && removable }"
      />
    </div>
    <div class="label-input" v-if="customCondition">
      <InputText
        :class="{ 'p-invalid': wrongTextMessage }"
        id="added-text"
        type="text"
        :required="true"
        :disabled="dropdownLoading"
        v-model.trim="addedText"
        placeholder="Value"
        @change="textInputChangeHandler($event)"
        :maxlength="100"
      />
    </div>
    <base-button
      type="button"
      class="flat"
      v-if="removable"
      @click="emit('deleteField', inputName)"
    >
      <i class="pi pi-trash" style="font-size: 1rem; color: red"></i>
    </base-button>

    <InvalidInputMessage :wrongTextMessage="wrongTextMessage" />
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import helpers from "../../../services/helpers";
import InvalidInputMessage from "../InvalidInputMessage.vue";

/**
 * Props:
 * ******
 * - inputName should be in snake case ex: "snake_case".
 * - modelValue will be set as value for the drop down.
 */
const props = defineProps({
  inputName: { type: String, required: true },
  dropdownInvalid: { type: Boolean, required: false },
  modelValue: { type: String, required: true },
  dropdownOptions: { type: Array, required: true },
  addedText: { type: String, required: false },
  dropdownLoading: { type: Boolean, required: false },
  optionLabel: { type: String, required: false },
  optionValue: { type: String, required: false },
  removable: { type: Boolean, required: false },
  labelLink: { type: String, required: false },
});

const emit = defineEmits([
  "update:modelValue",
  "addedTextChanged",
  "deleteField",
]);

// Reactive variables:
const selectedOption = ref(props.modelValue);
const addedText = ref(props.addedText);
const wrongTextMessage = ref(null);

// Variables
const label = helpers.convertSnakeCaseToTitle(props.inputName);

//Computed Props
const customCondition = computed(() => {
  return (
    selectedOption.value === "custom_field_product" ||
    selectedOption.value === "custom_field_variant" ||
    selectedOption.value === "custom_text"
  );
});

// listen to changes from parent
watch(
  () => [props.modelValue, props.addedText],
  (newValues) => {
    selectedOption.value = newValues[0];
    addedText.value = newValues[1];

    if (newValues[0].includes("custom"))
      validateText(newValues[1] ? newValues[1] : "");
  }
);

// Methods
const dropdownChangeHandler = (event) => {
  emit("update:modelValue", event.value);
  addedText.value = "";
};
const textInputChangeHandler = (event) => {
  const userEnteredText = event.target.value;
  validateText(userEnteredText);
  emit(
    "addedTextChanged",
    props.inputName,
    selectedOption.value,
    userEnteredText
  );
};

const validateText = (text) => {
  if (text.trim() === "") {
    wrongTextMessage.value = "Custom fields can't be empty";
  } else if (
    text.trim().includes(" ") &&
    selectedOption.value !== "custom_text"
  ) {
    wrongTextMessage.value = "Spaces are not allowed on custom fields name";
  } else {
    wrongTextMessage.value = null;
  }
};
</script>

<style scoped lang="scss">
.dropdown-id-contanier {
  display: flex;
  gap: 10px;

  .label-input {
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    input#added-text {
      width: 225px;
    }
    input#added-text-preview {
      width: 225px;
    }
    label {
      min-width: 75px;
      a {
        &:link,
        &:visited {
          text-decoration: none;
          cursor: pointer;
        }
        &:hover {
          color: blue;
        }
      }
    }
  }
}
</style>
