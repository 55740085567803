import axios from "axios";

export default {
  /*
  Function that convert snake case string to string with first capital letter in each word seperated by single white space
  Example: "snake_case" => "Snake Case"
  */
  convertSnakeCaseToTitle(string = "") {
    if (string.includes("_")) {
      return string
        .split("_")
        .map((word) => word[0].toUpperCase() + word.substring(1, word.length))
        .join(" ");
    }
    return string[0].toUpperCase() + string.substring(1, string.length);
  },
  /*
  Function that convert snake case string to pascal case string.
  Example: "snake_case" => "snakeCase"
  */
  convertSnakeCaseToPascalCase(string) {
    return string
      .split("_")
      .map((word, index) =>
        index === 1
          ? word[0].toUpperCase() + word.substring(1, word.length)
          : word
      )
      .join("");
  },
  //function that takes in string and convert it to array of strings
  stringToArray(string) {
    if (string === "") {
      return [];
    }
    return eval(string);
  },
  // function that takes in the wrong images cdn and correct them
  // it corrects the cdn that contain '[{"image_url":'
  formatImageLink(string) {
    if (string.includes('[{"image_url":')) {
      return string.replace('[{"image_url":', "").slice(1, -1);
    }
    return string;
  },
  formatTimeStamp(dateUnixTimeStamp) {
    function addZeroToTheLeft(text) {
      return text.length === 2 ? text : "0" + text;
    }
    // format date unix to "yyyy-mm-dd hh-mm-ss"
    const date = new Date(dateUnixTimeStamp * 1000);
    const seconds = addZeroToTheLeft(String(date.getSeconds()));
    const minutes = addZeroToTheLeft(String(date.getMinutes()));
    const hours = addZeroToTheLeft(String(date.getHours()));
    const day = addZeroToTheLeft(String(date.getDate()));
    const month = addZeroToTheLeft(String(date.getMonth() + 1));
    const year = String(date.getFullYear());

    return `${year}-${month}-${day} at ${hours}:${minutes}:${seconds}`;
  },
  getHeader() {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("auth_google")}`,
    };
  },
  /**
   * @param {string} endPoint https://gpfeed-api.numinix.com/{endPoint}
   * @param {string} method examples: "get" , "post".
   * @param {object} params request parameters object.
   */
  async sendRequest(endPoint, method, params = {}) {
    // GET METHOD
    let url = `https://gpfeed-api.numinix.com/${endPoint}`;
    if (method === "get") {
      // GET METHOD WITH EMPTY PARAMS
      if (Object.keys(params).length === 0) {
        return axios
          .get(url, {
            headers: this.getHeader(),
          })
          .then((res) => res.data);
      }
      // GET METHOD WITH PARAMS
      return axios
        .get(url, {
          params: {
            dt_params: JSON.stringify({ ...params }),
          },
          headers: this.getHeader(),
        })
        .then((res) => res.data);
    }
    // Post Method or Put
    if (method === "post") {
      return axios
        .post(url, params, {
          headers: this.getHeader(),
        })
        .then((res) => res.data);
    }
    // put Method
    if (method === "put") {
      return axios
        .put(url, params, {
          headers: this.getHeader(),
        })
        .then((res) => res.data);
    }
    if (!endPoint || !method) {
      return new Error(
        "End Point or method is missing from sendRequest function"
      );
    }
  },
  findIdDocumentationLinks(idName = "") {
    const url = "https://support.google.com/merchants/answer/";

    if (idName === "id") return url + "6324405";
    if (idName === "gtin") return url + "6324461";
    if (idName === "brand") return url + "6324351";
    if (idName === "color") return url + "6324479";
    if (idName === "price") return url + "6324487";
    if (idName === "gender") return url + "6324371";
    if (idName === "condition") return url + "6324469";
    if (idName === "sale_price") return url + "6324471";
    if (idName === "description") return url + "6324468";
    if (idName === "item_group_id") return url + "6324507";
    if (idName === "google_product_category") return url + "6324436";
    if (idName === "shipping_label") return url + "6324504";

    //////////////////////////////////////////////////////
    if (/^custom_label_/.test(idName)) return url + "6324473";
    if (idName === "ads_redirect") return url + "6324450";
    if (idName === "adult") return url + "6324508";
    if (idName === "age_group") return url + "6324463";
    if (idName === "availability") return url + "6324448";
    if (idName === "availability_date") return url + "6324470";
    if (idName === "additional_image_links") return url + "6324370";
    if (idName === "energy_efficiency_class") return url + "7562785";
    if (idName === "excluded_destination") return url + "6324486";
    if (idName === "expiration_date") return url + "6324499";
    if (idName === "external_seller_id") return url + "11537846";
    if (idName === "identifier_exists") return url + "6324478";
    if (idName === "is_bundle") return url + "6324449";
    if (idName === "lifestyle_image_link") return url + "9103186";
    if (idName === "material") return url + "6324410";
    if (idName === "multipack") return url + "6324488";
    if (idName === "size_type") return url + "6324497";

    //////////////////////////////////////////////////////
    if (idName === "included_destination") return url + "7501026";
    if (idName === "installment") return url + "6324474";
    if (idName === "image_link") return url + "6324350";
    if (idName === "link") return url + "6324416";
    if (idName === "pattern") return url + "6324483";
    if (idName === "size") return url + "6324492";
    if (idName === "size_system") return url + "6324502";
    if (idName === "shipping") return url + "6324484";
    if (idName === "tax") return url + "6324454";
    if (idName === "tax_category") return url + "7569847";
    if (idName === "title") return url + "6324415";

    return null;
  },
};
