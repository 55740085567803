<template>
  <!-- <Toast /> -->

  <div style="display: flex; flex-direction: column; gap: 20px">
    <WrapperCard>
      <form class="settings">
        <heading-five> Merchant Id and Sync Settings </heading-five>
        <div class="label-input">
          <label for="merchant_id">Merchant ID</label>
          <Dropdown
            :loading="loginStore.loading"
            v-model="loginStore.selectedMerchantId"
            :options="loginStore.merchantIdsArray"
            optionLabel="id"
            optionValue="id"
            placeholder="Select a Merchant Id"
            style="min-width: 200px"
            @change="unsavedChanges"
          />
        </div>

        <div class="label-input">
          <label for="sync">Sync products to google</label>

          <ToggleButton
            input-id="sync"
            @change="unsavedChanges"
            v-model="loginStore.syncStatus"
            class="w-6rem"
            on-label="ON"
            off-label="OFF"
          />
        </div>
        <div class="actions">
          <base-button
            type="submit"
            @click.prevent="loginStore.submitForm(loginStore.syncStatus)"
            :disabled="loginStore.loadingForm || !loginStore.selectedMerchantId"
            >Save Settings</base-button
          >
        </div>
      </form>
    </WrapperCard>
  </div>
</template>

<script setup>
import { onMounted, defineEmits } from "vue";

import HeadingFive from "@/components/UI/Headings/HeadingFive";
import WrapperCard from "@/components/UI/WrapperCard.vue";
import googleLoginStore from "@/stores/googleLoginStore";
import ToggleButton from "primevue/togglebutton";

/********************* variables and state define *********************/
const loginStore = googleLoginStore();

const emit = defineEmits(["getTitle"]);

/********************* Functions Declartions *********************/
const unsavedChanges = () => {
  loginStore.unsavedChangesForm = true;
};

/********************* lifecycle hooks and watchers*********************/
onMounted(() => {
  emit("getTitle", "Settings");
  loginStore.getSyncStatus();
});
</script>

<style scoped lang="scss">
form.settings {
  padding: 30px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.label-input {
  display: flex;
  align-items: center;
  gap: 15px;
}

@media only screen and (max-width: 1200px) {
  form.settings {
    max-width: 75%;
  }
}
@media only screen and (max-width: 1200px) {
  form.settings {
    max-width: 60%;
  }
}
@media only screen and (max-width: 1000px) {
  form.settings {
    max-width: 75%;
  }
}
@media only screen and (max-width: 800px) {
  form.settings {
    max-width: 100%;
  }
}
</style>
