<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="8" fill="#1C5CFF" />
    <path
      d="M27.6596 31.0504H22.9724H21.7103H21.4377V24.7633H23.4939C24.0153 24.7633 24.3235 24.1707 24.0153 23.7441L20.0155 18.2095C19.7607 17.854 19.2333 17.854 18.9785 18.2095L14.9787 23.7441C14.6706 24.1707 14.9728 24.7633 15.5002 24.7633H17.5564V31.0504H17.2838H16.0217H10.5879C7.47691 30.8785 5 27.969 5 24.8166C5 22.6419 6.1792 20.7457 7.92726 19.7206C7.76727 19.288 7.68431 18.8258 7.68431 18.3399C7.68431 16.1178 9.47977 14.3223 11.7019 14.3223C12.1819 14.3223 12.6441 14.4053 13.0766 14.5653C14.3625 11.8395 17.1357 9.94922 20.3592 9.94922C24.5309 9.95514 27.9677 13.1491 28.3588 17.22C31.5646 17.771 34 20.7398 34 24.0996C34 27.6905 31.2031 30.8015 27.6596 31.0504Z"
      fill="white"
    />
  </svg>
</template>
