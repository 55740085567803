<template>
  <div style="display: flex; justify-content: space-between">
    <div class="all-saved">
      <i
        class="pi pi-exclamation-triangle"
        style="color: #ffb600; font-size: 1.25rem"
      ></i>
      <p><slot>please enter warning message!</slot></p>
    </div>
  </div>
</template>

<style scoped>
.all-saved {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
  border: 1px solid rgb(255, 182, 0);
  color: rgb(19, 19, 19);
  padding: 0.5rem 1rem;
  margin-top: auto;
  background-color: antiquewhite;
  border-radius: 5px;
}
</style>
