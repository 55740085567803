<template>
  <!--This page will be shown only when the user has not selected a mechant id yet -->
  <form class="page-container" @submit.prevent="store.saveMerchantId">
    <p>Please select your Merchent Id</p>
    <div style="display: flex; gap: 15px">
      <Dropdown
        :class="{ 'p-invalid': store.emptyInputMerchantIdError }"
        v-model="store.selectedMerchantId"
        :options="store.merchantIdsArray"
        optionLabel="id"
        placeholder="Select a Merchant Id"
        style="width: 300px"
      />
      <InlineMessage
        v-show="store.emptyInputMerchantIdError"
        severity="error"
        style="width: fit-content"
        >Merchant ID is required</InlineMessage
      >
      <InlineMessage
        v-show="store.noMerchantAccountError"
        severity="error"
        style="width: fit-content"
        >Google merchant store is missing!</InlineMessage
      >
    </div>
    <div style="display: flex; gap: 15px">
      <base-button class="submit-btn" type="submit">
        Save Selection
      </base-button>
      <base-button
        class="flat"
        type="button"
        v-show="store.noMerchantAccountError"
        @click="store.signInGoogle(true)"
      >
        Login with another account
      </base-button>
    </div>
  </form>
</template>

<script setup>
//vue
import { onMounted } from "vue";
import googleLoginStore from "@/stores/googleLoginStore";
//primevue
import InlineMessage from "primevue/inlinemessage";

const store = googleLoginStore();

onMounted(() => {
  // If this component mounted this means merchant id is empty user must set it.
  // fetch available merchant_ids and set them as dropdown value
  store.getMerchantIds();
});
</script>

<style scoped lang="scss">
form.page-container {
  padding: 32px;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  button {
    margin-top: 10px;
    width: fit-content;
  }
}
</style>
