<template>
  <div class="actions-container">
    <template v-if="!firstTimeSubmitting">
      <base-button
        type="button"
        class="flat red-text"
        @click="resetSettingsHandler(true)"
        :disabled="settingsStore.settingsLoading"
      >
        Reset Settings
      </base-button>
      <base-button
        type="button"
        class="flat"
        @click="disacrdChangesHandler"
        :disabled="settingsStore.settingsLoading"
        >Discard Changes
      </base-button>
    </template>
    <base-button type="submit" :disabled="settingsStore.settingsLoading">
      <span
        :style="{
          display: settingsStore.unsavedChanges ? 'inline-block' : 'none',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: settingsStore.unsavedChanges
            ? 'white'
            : 'transparent',
        }"
      ></span>
      Save Settings
    </base-button>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import googleSettingsStore from "@/stores/settingsStore";
import toastStore from "@/stores/toastStore";

const settingsStore = googleSettingsStore();
const notifications = toastStore();

defineProps({
  firstTimeSubmitting: {
    required: true,
    type: Boolean,
  },
});

const resetSettingsHandler = async () => {
  if (settingsStore.currentSettingsIsDefault) {
    notifications.showNotification(
      "info",
      "Settings are not resetted",
      "Settings already have the default value!",
      1500
    );
  } else {
    await settingsStore.getUserSettings(true);
    settingsStore.unsavedChanges = true;
    notifications.showNotification(
      "success",
      "Settings are resetted",
      "Settings are resetted, please save the applied changes!",
      2000
    );
  }
};

const disacrdChangesHandler = async () => {
  if (settingsStore.unsavedChanges) {
    await settingsStore.getUserSettings();
    notifications.showNotification(
      "success",
      "Settings Discarded",
      "All changes have been discarded",
      1500
    );
  } else {
    notifications.showNotification(
      "info",
      "No Changes to Discard",
      "There is no changes to be discarded!",
      1500
    );
  }
};
</script>

<style scoped lang="scss">
.actions-container {
  display: flex;
  gap: 10px;
  align-self: flex-start;
  margin-top: 20px;
}
</style>
