<!-- 
  This Statistics Card should be reusable for all plugins:
  1- props needed from parent: title and count to be shown.
  2- classes and colors are adjusted here.
 -->
<template>
  <div class="stats-card">
    <p class="stats-card-title">{{ cardTitle }}</p>
    <div class="stats-card-body">
      <p>{{ cardCount }}</p>
      <slot>
        <p>Insert Image or Svg Here</p>
      </slot>
    </div>
    <div class="stats-card-footer"></div>
  </div>
</template>
<script>
export default {
  props: ["cardTitle", "cardCount"],
};
</script>
<style scoped lang="scss">
.stats-card {
  padding-top: 21px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-width: 288px;
  height: 132px;
  gap: 20px;
  border-radius: 8px;

  .stats-card-title {
    padding-left: 17px;
    padding-right: 17px;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
  }
  .stats-card-body {
    padding-left: 17px;
    padding-right: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 36px;
      line-height: 40px;
    }
  }

  &.total-products .stats-card-footer {
    height: 16px;
    background-color: #839dfb;
  }
  &.total-products-errors .stats-card-footer {
    height: 16px;
    background-color: #fcb2b2;
  }
  &.warnings .stats-card-footer {
    height: 16px;
    background-color: #ffefa1;
  }
  &.pending .stats-card-footer {
    height: 16px;
    background-color: #ffc48d;
  }
  &.excluded .stats-card-footer {
    height: 16px;
    background-color: #93ffbe;
  }
}

.total-products {
  background: #abbeff;
  border-bottom: 3px solid #1c5cff;
  box-shadow: 0px 5px 19px #b5c5ff;
  //   background-color: #ffe5c7;
  //   border-bottom: 3px solid #ee962f;
  //   box-shadow: 0px 5px 19px #ffe5c7;
}
.total-products-errors {
  background: #ffd8d8;
  border-bottom: 3px solid #fd7575;
  box-shadow: 0px 5px 19px #ffd8d8;
}
.warnings {
  background: #fff8d6;
  border-bottom: 3px solid #f8d847;
  box-shadow: 0px 5px 19px #fff8d6;
}
.pending {
  background: #ffdcbc;
  border-bottom: 3px solid #f89c47;
  box-shadow: 0px 5px 19px #ffdcbc;
}
.excluded {
  background: #cfffe2;
  border-bottom: 3px solid #41cd79;
  box-shadow: 0px 5px 19px #cfffe2;
}

@media only screen and (max-width: 800px) {
  .stats-card {
    min-width: 240px;
  }
}
</style>
