<template>
  <div class="wrapper">
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip" style="z-index: 1">
      <slot name="content"></slot>
    </div>
    <transition name="fade">
      <div class="tooltip-container" v-if="show">
        <div class="arrow">
          <svg
            width="4"
            height="8"
            viewBox="0 0 4 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.9959 0L3.9959 8L0.799015 5.59926C-0.26634 4.79922 -0.266337 3.20078 0.799017 2.40074L3.9959 0Z"
              fill="#09101D"
            />
          </svg>
        </div>
        <div class="text-container">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showTooltip() {
      this.show = true;
    },
    hideTooltip() {
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
}
.tooltip-container {
  position: absolute;
  right: auto;
  top: -50%;
  right: 2px;
  transform: translateX(100%);
}

.text-container {
  background: #09101d;
  padding: 8px;
  border-radius: 8px;
  color: white;
  min-width: 165px;
  max-width: 210px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}

.arrow {
  position: absolute;
  left: -4px;
  top: 20px;
  width: 4px;
  height: 8px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
