import { createRouter, createWebHistory } from "vue-router";

import StatisticsCards from "@/views/MainApp/StatisticsCards.vue";
import ProductsTables from "@/views/MainApp/ProductsTables.vue";
import SettingsTab from "@/views/MainApp/SettingsTab.vue";
import AdvancedSettings from "@/views/MainApp/AdvancedSettings";
import WidgetApp from "@/views/WidgetApp.vue";

const routes = [
  { path: "/", redirect: "/google-feed" },
  { path: "/stats", redirect: "/google-feed" },
  { path: "/google-feed", component: StatisticsCards },
  { path: "/products", component: ProductsTables },
  { path: "/settings", component: SettingsTab },
  { path: "/mapping-settings", component: AdvancedSettings },
  { path: "/widgets", component: WidgetApp },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-tab",
});

export default router;
