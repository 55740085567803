<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8703_204893)">
      <path
        d="M23.0935 9.91258L13.3042 9.91211C12.8719 9.91211 12.5215 10.2625 12.5215 10.6947V13.822C12.5215 14.2542 12.8719 14.6046 13.3041 14.6046H18.8169C18.2132 16.1712 17.0865 17.4832 15.6491 18.3168L17.9997 22.386C21.7704 20.2052 23.9997 16.3789 23.9997 12.0955C23.9997 11.4857 23.9548 11.0497 23.8649 10.5587C23.7965 10.1858 23.4727 9.91258 23.0935 9.91258Z"
        fill="#167EE6"
      />
      <path
        d="M12.0003 19.3037C9.30242 19.3037 6.94723 17.8297 5.68231 15.6484L1.61328 17.9938C3.68398 21.5826 7.56308 23.9994 12.0003 23.9994C14.177 23.9994 16.2309 23.4133 18.0003 22.3919V22.3863L15.6496 18.3171C14.5744 18.9408 13.3302 19.3037 12.0003 19.3037Z"
        fill="#12B347"
      />
      <path
        d="M18 22.3932V22.3876L15.6494 18.3184C14.5741 18.9419 13.33 19.3049 12 19.3049V24.0006C14.1767 24.0006 16.2308 23.4145 18 22.3932Z"
        fill="#0F993E"
      />
      <path
        d="M4.69566 12.0003C4.69566 10.6705 5.05856 9.42637 5.68205 8.3512L1.61302 6.00586C0.586031 7.76962 0 9.81797 0 12.0003C0 14.1826 0.586031 16.2309 1.61302 17.9947L5.68205 15.6494C5.05856 14.5742 4.69566 13.3301 4.69566 12.0003Z"
        fill="#FFD500"
      />
      <path
        d="M12.0003 4.69566C13.7595 4.69566 15.3755 5.32078 16.6377 6.36061C16.9491 6.61711 17.4017 6.59859 17.6869 6.31336L19.9027 4.09758C20.2263 3.77395 20.2033 3.24422 19.8575 2.94431C17.7428 1.10967 14.9912 0 12.0003 0C7.56308 0 3.68398 2.41673 1.61328 6.00558L5.68231 8.35092C6.94723 6.16969 9.30242 4.69566 12.0003 4.69566Z"
        fill="#FF4B26"
      />
      <path
        d="M16.6374 6.36061C16.9488 6.61711 17.4015 6.59859 17.6866 6.31336L19.9024 4.09758C20.226 3.77395 20.2029 3.24422 19.8573 2.94431C17.7425 1.10963 14.991 0 12 0V4.69566C13.7592 4.69566 15.3752 5.32078 16.6374 6.36061Z"
        fill="#D93F21"
      />
    </g>
    <defs>
      <clipPath id="clip0_8703_204893">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
