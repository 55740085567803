import countriesData from "@/assets/json/countries.json";
import languages from "@/assets/json/languages.json";

export default {
  removeAddedTextFromIdType(idType) {
    if (idType.includes("custom_field_variant_")) {
      return "custom_field_variant";
    } else if (idType.includes("custom_field_product_")) {
      return "custom_field_product";
    } else if (idType.includes("custom_text_")) {
      return "custom_text";
    } else {
      return idType;
    }
  },

  extractAddedTextFromIdType(idType) {
    if (
      idType.includes("custom_field_variant") ||
      idType.includes("custom_field_product") ||
      idType.includes("custom_text")
    ) {
      return idType
        .replace("custom_field_variant_", "")
        .replace("custom_field_product_", "")
        .replace("custom_text_", "");
    }
    return "";
  },
  findIdDocumentationLinks(idName = "") {
    const url = "https://support.google.com/merchants/answer/";

    if (idName === "id") return url + "6324405";
    if (idName === "gtin") return url + "6324461";
    if (idName === "brand") return url + "6324351";
    if (idName === "color") return url + "6324479";
    if (idName === "price") return url + "6324487";
    if (idName === "gender") return url + "6324371";
    if (idName === "condition") return url + "6324469";
    if (idName === "sale_price") return url + "6324471";
    if (idName === "description") return url + "6324468";
    if (idName === "item_group_id") return url + "6324507";
    if (idName === "google_product_category") return url + "6324436";
    if (idName === "shipping_label") return url + "6324504";

    //////////////////////////////////////////////////////
    if (/^custom_label_/.test(idName)) return url + "6324473";
    if (idName === "ads_redirect") return url + "6324450";
    if (idName === "adult") return url + "6324508";
    if (idName === "age_group") return url + "6324463";
    if (idName === "availability") return url + "6324448";
    if (idName === "availability_date") return url + "6324470";
    if (idName === "additional_image_links") return url + "6324370";
    if (idName === "energy_efficiency_class") return url + "7562785";
    if (idName === "excluded_destination") return url + "6324486";
    if (idName === "expiration_date") return url + "6324499";
    if (idName === "external_seller_id") return url + "11537846";
    if (idName === "identifier_exists") return url + "6324478";
    if (idName === "is_bundle") return url + "6324449";
    if (idName === "lifestyle_image_link") return url + "9103186";
    if (idName === "material") return url + "6324410";
    if (idName === "multipack") return url + "6324488";
    if (idName === "size_type") return url + "6324497";

    //////////////////////////////////////////////////////
    if (idName === "included_destination") return url + "7501026";
    if (idName === "installment") return url + "6324474";
    if (idName === "image_link") return url + "6324350";
    if (idName === "link") return url + "6324416";
    if (idName === "pattern") return url + "6324483";
    if (idName === "size") return url + "6324492";
    if (idName === "size_system") return url + "6324502";
    if (idName === "shipping") return url + "6324484";
    if (idName === "tax") return url + "6324454";
    if (idName === "tax_category") return url + "7569847";
    if (idName === "title") return url + "6324415";

    return null;
  },
  ID_TYPES_OPTIONS: [
    "product_description",
    "product_name",
    "variant_id",
    "product_id",
    "product_brand",
    "product_model",
    "variant_price",
    "product_type",
    "variant_barcode",
    "variant_discount_price",
    "custom_field_product",
    "custom_field_variant",
    "custom_text",
    "product_seo_title",
    "product_categories",
  ],
  ID_DROPDOWN_FIELDS: [
    "id",
    "gtin",
    "name",
    "brand",
    "color",
    "model",
    "price",
    "gender",
    "barcode",
    "condition",
    "sale_price",
    "description",
    "item_group_id",
    "google_product_category",
    "shipping_label",
  ],
  GOOGLE_INPUT_FIELDS: [
    { fieldName: "shipping_value", fieldType: "number" },
    { fieldName: "currency", fieldType: "text" },
    { fieldName: "rule_query", fieldType: "text" },
  ],
  ID_DROPDOWN_GOOGLE_FIELDS: [
    "custom_label_0",
    "custom_label_1",
    "custom_label_2",
    "custom_label_3",
    "custom_label_4",
    "ads_redirect",
    "ads_grouping",
    "ads_labels",
    "adult",
    "age_group",
    "availability",
    "availability_date",
    "additional_image_link",
    "additional_size_type",
    "display_ads_title",
    "display_ads_value",
    "energy_efficiency_class",
    "excluded_destination",
    "expiration_date",
    "external_seller_id",
    "feed_label",
    "identifier_exists",
    "is_bundle",
    "kind",
    "lifestyle_image_link",
    "material",
    "multipack",
    "pickup_method",
    "size_type",
    "sizes",
    "mpn",
  ],

  OTHER_DROPDOWN_FIELDS: [
    "dimension_unit",
    "weight_unit",
    "target_country",
    "content_language",
  ],

  otherFieldsDataInitialValue: {
    dimension_unit: {
      value: "",
      options: [],
    },
    weight_unit: {
      value: "",
      options: [],
    },
    target_country: {
      value: "",
      options: countriesData[0].countries,
    },
    content_language: {
      value: "",
      options: languages,
      optionLabel: "name",
      optionValue: "name",
    },
  },
};
