<template>
  <div v-if="settingsStore.idFieldsData.length > 0" class="flex-col-gap-1">
    <custom-dropdown
      v-for="element in settingsStore.idFieldsData"
      :labelLink="
        settingsStoreHelpers.findIdDocumentationLinks(element.fieldName)
      "
      :key="element.fieldName"
      :inputName="element.fieldName"
      :addedText="element.addedText"
      :modelValue="element.selectedType"
      :dropdownLoading="settingsStore.settingsLoading"
      :dropdownOptions="settingsStoreHelpers.ID_TYPES_OPTIONS"
      :removable="element.removable"
      @added-text-changed="updateAddedText"
      @delete-field="deleteFieldHandler"
      @update:modelValue="updateDropdownHandler($event, element.fieldName)"
    />
    <custom-dropdown
      v-for="(fieldData, fieldName) in settingsStore.otherFieldsData"
      :key="fieldName"
      :inputName="fieldName"
      :modelValue="fieldData.value"
      :dropdownOptions="fieldData.options"
      :dropdownLoading="settingsStore.settingsLoading"
      :optionLabel="fieldData.optionLabel"
      :optionValue="fieldData.optionValue"
      @update:modelValue="updateDropdownHandler($event, fieldName)"
    />
    <base-button
      class="flat"
      type="button"
      :disabled="settingsStore.settingsLoading"
      style="align-self: flex-start; margin-top: 15px"
      @click="addPropertyModalVisibility = true"
    >
      <i class="pi pi-plus" style="font-size: 1rem"></i>
      <span> Add More Properties </span>
    </base-button>
  </div>

  <modal-overlay
    v-if="addPropertyModalVisibility"
    :hideModalFn="hideAddPropertyModal"
  >
    <AddIdProperty :hideModalFn="hideAddPropertyModal" />
  </modal-overlay>
</template>

<script setup>
import { ref } from "vue";
import AddIdProperty from "@/components/Forms/AddIdProperty";
import googleSettingsStore from "@/stores/settingsStore";
import settingsStoreHelpers from "@/stores/settingsStoreHelpers";

const settingsStore = googleSettingsStore();

const addPropertyModalVisibility = ref(false);

//Functions and Methods
const hideAddPropertyModal = () => {
  addPropertyModalVisibility.value = false;
};

const deleteFieldHandler = (fieldName) => {
  settingsStore.removeField(fieldName);
};

const updateDropdownHandler = (payload, fieldName) => {
  if (
    [
      "weight_unit",
      "target_country",
      "content_language",
      "dimension_unit",
    ].includes(fieldName)
  ) {
    settingsStore.updateOtherFieldsData(fieldName, payload);
  } else {
    settingsStore.updateIdFieldsData(fieldName, {
      fieldName,
      selectedType: payload,
      addedText: "",
    });
  }
};

const updateAddedText = (fieldName, selectedType, addedText) => {
  settingsStore.updateIdFieldsData(fieldName, {
    fieldName,
    selectedType,
    addedText,
  });
};
</script>

<style lang="scss" scoped>
.pl-2 {
  padding-left: 20px;
}

.flex-col-gap-1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
</style>
