<template>
  <Toast />
  <!-- Lexmodo Authentication -->
  <div v-if="statusApp">
    <!-- show the main app -->
    <template v-if="!productWidget">
      <UpperBar title="Lexmodo Google Plugin Feed">
        <PluginLogoSvg></PluginLogoSvg>
      </UpperBar>
      <!-- Google Authentication(if user is not logged in) -->
      <ConnectGoogle v-if="!loginStore.isUserLoggedIn" />
      <!-- Loading state (if data is being fetched) -->
      <div class="app-loading" v-else-if="loginStore.loading">
        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
      </div>
      <!-- Merchant Id Select (if user is logged in but didn't select merchant id) -->
      <MerchantIdSelect v-else-if="!loginStore.merchantIdExist" />
      <!-- MainRoutes (if user is logged in and selected merchant id) -->
      <div v-else-if="!settingsStore.isUserSavedSettings" class="app-settings">
        <h2 class="settings-header">Please select your settings:</h2>
        <AdvancedSettings :firstTimeSubmitting="true" />
      </div>
      <MainApp v-else />
    </template>
    <!-- show widget app -->
    <template v-else>
      <MainApp v-if="showWidget" :widgetMode="true" :widgetData="widgetData" />
      <div v-else class="widget-warning">
        <i
          class="pi pi-exclamation-triangle"
          style="font-size: 1.5rem; color: #ffcc00"
        ></i>
        <p>Please login with google account and select merchant id</p>
      </div>
    </template>
  </div>
  <div v-else class="app-loading">
    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
  </div>
</template>

<script>
import MainApp from "./views/MainApp/MainApp.vue";
import ConnectGoogle from "./views/ConnectGoogle.vue";
import MerchantIdSelect from "./views/MerchantIdSelect.vue";
import UpperBar from "./components/UI/UpperBar/UpperBar.vue";
import PluginLogoSvg from "./components/UI/UpperBar/PluginLogoSvg.vue";
// import SettingsTab from "./views/MainApp/SettingsTab.vue";
import AdvancedSettings from "./views/MainApp/AdvancedSettings.vue";

import googleLoginStore from "./stores/googleLoginStore";
import googleSettingsStore from "./stores/settingsStore";

import { useRouter } from "vue-router";
import { authLogin } from "./services/authLogin";
import { ref } from "vue";

export default {
  components: {
    MainApp,
    UpperBar,
    PluginLogoSvg,
    ConnectGoogle,
    MerchantIdSelect,
    // SettingsTab,
    AdvancedSettings,
  },
  name: "App",
  computed: {
    /**
     * This proerty will be used when rendering the widget only
     * To check if user has logged in and selected a merchant
     * so he can access widget app
     */
    showWidget() {
      return this.loginStore.merchantIdExist && this.loginStore.isUserLoggedIn;
    },
  },
  setup() {
    //declare variables
    const loginStore = googleLoginStore(),
      settingsStore = googleSettingsStore();
    const pathName = ref(""),
      widgetData = ref({}),
      productWidget = ref(false),
      merchantIdExist = ref(false);

    //declare functions
    const updateMerchantIdExist = () => {
      merchantIdExist.value = true;
    };

    // authentication
    const statusApp = ref(false),
      auth = new authLogin(),
      router = useRouter();

    auth
      .startApp()
      ?.then((data) => {
        if (data.meta.google_logged_in) {
          loginStore.localLogin();
        } else {
          loginStore.localLogout();
        }
        if (!data.meta.redirect) {
          if (data.product_widget?.product_widget === true) {
            widgetData.value = data;
            statusApp.value = true;
            productWidget.value = true;
            router.push("/widgets");
          } else {
            statusApp.value = true;

            router.push("/google-feed");
          }
        }
      })
      .then(() => {
        loginStore.checkMerchantIdExist();
      });
    return {
      statusApp,
      pathName,
      widgetData,
      productWidget,
      merchantIdExist,
      updateMerchantIdExist,
      loginStore,
      settingsStore,
    };
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  padding: 44px;
  min-height: 100vh;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: #f2f5fa;
  scrollbar-gutter: "stable";
}

.app-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.page-container {
  background: #ffffff;
  border: 1px solid #edeeef;
  border-radius: 12px;
  box-shadow: 0px 10px 13px -2px #6a73790d;
  position: relative;
}
.widget-warning {
  display: flex;
  gap: 20px;
}
.app-settings {
  padding: 15px;
}
.settings-header {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}
@media only screen and (max-width: 1200px) {
  #app {
    padding: 40px;
  }
}
@media only screen and (max-width: 1000px) {
  #app {
    padding: 35px;
  }
}
@media only screen and (max-width: 800px) {
  #app {
    padding: 30px;
  }
}
@media only screen and (max-width: 600px) {
  #app {
    padding: 25px;
  }
}
@media only screen and (max-width: 400px) {
  #app {
    padding: 20px;
  }
}
@media only screen and (max-width: 350px) {
  #app {
    padding: 15px;
  }
}
</style>
