<template>
  <div class="stats-main">
    <div class="title-bar">
      <p>View analytics for your store.</p>
      <div style="display: flex; flex-direction: column; gap: 5px">
        <label for="date-picker">Select from to dates:</label>
        <Dropdown
          @change="dateRangeSelect"
          :options="filterOptions"
          v-model="appCounterStore.date"
          optionLabel="name"
          optionValue="value"
          style="width: 150px; border: 1px solid #d8d8d8; border-radius: 8px"
        />
      </div>
    </div>
    <div class="cards-container">
      <stats-card
        v-for="card in cardsArray"
        :class="card.customClass"
        :cardCount="card.cardCount"
        :cardTitle="card.cardTitle"
        :key="card.cardTitle"
      >
        <component :is="card.svgComponent" />
      </stats-card>
    </div>
  </div>
</template>

<script setup>
import StatsCard from "@/components/StatisticsComponents/StatsCard.vue";

//This to solve key issue when rendering the components
import {
  markRaw,
  onMounted,
  ref,
  defineEmits,
  computed,
  onUnmounted,
} from "vue";

//Svg Icons import
import TotalProductsSvg from "@/components/StatisticsComponents/Icons/TotalProductsSvg.vue";
import TotalProductsErrorsSvg from "@/components/StatisticsComponents/Icons/TotalProductsErrorsSvg.vue";
import PendingSvg from "@/components/StatisticsComponents/Icons/PendingSvg.vue";
import ExcludedSvg from "@/components/StatisticsComponents/Icons/ExcludedSvg.vue";

import countersStore from "@/stores/countersStore";

// lifecycle hooks
onMounted(() => {
  emit("getTitle", "Dashboard");
  appCounterStore.getCounters();
});
onUnmounted(() => {
  // reset filter if component is unmounted
  appCounterStore.resetDateFilter();
});

// declare variables and state
const emit = defineEmits(["getTitle"]);
const appCounterStore = countersStore();

const filterOptions = ref([
  { name: "Yesterday", value: 1 },
  { name: "Last 7 days", value: 7 },
  { name: "Last 30 days", value: 30 },
  { name: "Last 90 days", value: 90 },
  { name: "All time", value: "all_time" },
]);

// declare computed and watcher
const allProductsCount = computed(() => appCounterStore.allProductsCount);
const productsWithErrorsCount = computed(
  () => appCounterStore.productsWithErrorsCount
);
const submittedProductsCount = computed(
  () => appCounterStore.submittedProductsCount
);
const excludedProductsCount = computed(
  () => appCounterStore.excludedProductsCount
);

const cardsArray = ref([
  {
    cardCount: allProductsCount,
    cardTitle: "Total Prodcuts Uploaded",
    customClass: "total-products",
    svgComponent: markRaw(TotalProductsSvg),
  },
  {
    cardCount: productsWithErrorsCount,
    cardTitle: "Total Products Errors",
    customClass: "total-products-errors",
    svgComponent: markRaw(TotalProductsErrorsSvg),
  },
  {
    cardCount: submittedProductsCount,
    cardTitle: "Submitted",
    customClass: "pending",
    svgComponent: markRaw(PendingSvg),
  },
  {
    cardCount: excludedProductsCount,
    cardTitle: "Excluded",
    customClass: "excluded",
    svgComponent: markRaw(ExcludedSvg),
  },
]);

//function declarions
const dateRangeSelect = () => {
  appCounterStore.getCounters();
};
</script>

<style scoped lang="scss">
.stats-main {
  padding: 25px;
}
.title-bar {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
}
.cards-container {
  justify-content: center;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 17px;
}

@media only screen and (max-width: 1650px) {
  .cards-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 1400px) {
  .cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1050px) {
  .cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .cards-container {
    grid-template-columns: 1fr;
  }

  .title-bar {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
