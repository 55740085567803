<template>
  <nav>
    <!-- 
      This nav buttons should request data from backend when click
     -->
    <ul>
      <li v-for="(tab, index) in navTabs" :key="tab.id">
        <button
          @click="setCurrentTab(tab.tabComponent, index)"
          :class="currentTab === tab.tabComponent ? 'active' : ''"
        >
          <span>
            {{ tab.tabName }}
          </span>
          <span
            class="count"
            :class="{
              all: tab.tabName === 'All Products',
              errors: tab.tabName === 'With Error',
              pending: tab.tabName === 'Pending',
              submitted: tab.tabName === 'Submitted',
              warnings: tab.tabName === 'With Warnings',
              excluded: tab.tabName === 'Excluded',
            }"
            >{{ tab.dataCount }}</span
          >
        </button>
      </li>
    </ul>
    <hr class="nav-hr" />
  </nav>

  <div class="tab-content">
    <!-- 
      - use mitt to communicate between SearchBar and CustomTable (@/service/eventBus.js)
      - when user type something and hit search button inside the search component,
          the table component should be notified and send a request with search keywords
          and update tables based on those keywords.
      - add filter and clear filter buttons to the search bar.
     -->
    <SearchBar />
    <CustomTable :tabName="navTabs[currentTabIndex].tabComponent" />
  </div>
</template>

<script setup>
import CustomTable from "@/components/ProductsTablesComponents/CustomTable.vue";
import SearchBar from "@/components/GeneralComponents/SearchBar.vue";
import countersStore from "@/stores/countersStore";
import { ref, defineEmits, onMounted, computed } from "vue";

// lifecycle hooks
onMounted(() => {
  emit("getTitle", "Products");
  appCounterStore.getCounters();
});

//declare computed variables
const appCounterStore = countersStore();

const allProductsCount = computed(() => appCounterStore.allProductsCount);
const productsWithErrorsCount = computed(
  () => appCounterStore.productsWithErrorsCount
);
const submittedProductsCount = computed(
  () => appCounterStore.submittedProductsCount
);
const excludedProductsCount = computed(
  () => appCounterStore.excludedProductsCount
);

// declare variables and state
const emit = defineEmits(["getTitle"]);

const currentTabIndex = ref(0);
const currentTab = ref("all-products");
const navTabs = ref([
  {
    id: 0,
    tabName: "All Products",
    tabComponent: "all-products",
    dataCount: allProductsCount,
  },
  {
    id: 1,
    tabName: "With Error",
    tabComponent: "products-with-errors",
    dataCount: productsWithErrorsCount,
  },
  {
    id: 2,
    tabName: "Submitted",
    tabComponent: "submitted-products",
    dataCount: submittedProductsCount,
  },
  {
    id: 3,
    tabName: "Excluded",
    tabComponent: "excluded-products",
    dataCount: excludedProductsCount,
  },
]);

// functions declartions
const setCurrentTab = (tab, index) => {
  currentTab.value = tab;
  currentTabIndex.value = index;
};
</script>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: column;
  padding-left: 27px;
  padding-top: 22px;
  padding-right: 23px;
  height: 70px;
}

ul {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 12px;
  height: 100%;
  list-style: none;
}

.nav-hr {
  border-bottom: 1px solid #cecece;
  margin-top: auto;
}

nav button {
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  gap: 8px;
  height: 93%;
  color: #6d6d6d;
  outline: none;
  z-index: 1;
}

.active {
  box-shadow: 0 4px 0 #1575bf;
  color: #202223;
}

span.count {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 24px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 100px;
}
span.count.all {
  background: #1575bf;
}
span.count.errors {
  background: #c83e25;
}
span.count.pending {
  background: #004982;
}
span.count.submitted {
  background: #07d09e;
}
span.count.warnings {
  background: #ffc453;
}

span.count.excluded {
  background: #c83e25;
}
</style>
