<template>
  <tooltip-wrapper>
    <template #content>
      <svg
        style="transform: scale(0.6666666666)"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.1 21.212C9.31667 21.7373 10.6167 22 12 22C13.3833 22 14.6833 21.7373 15.9 21.212C17.1167 20.6873 18.175 19.975 19.075 19.075C19.975 18.175 20.6873 17.1167 21.212 15.9C21.7373 14.6833 22 13.3833 22 12C22 10.6167 21.7373 9.31667 21.212 8.1C20.6873 6.88333 19.975 5.825 19.075 4.925C18.175 4.025 17.1167 3.31233 15.9 2.787C14.6833 2.26233 13.3833 2 12 2C10.6167 2 9.31667 2.26233 8.1 2.787C6.88333 3.31233 5.825 4.025 4.925 4.925C4.025 5.825 3.31267 6.88333 2.788 8.1C2.26267 9.31667 2 10.6167 2 12C2 13.3833 2.26267 14.6833 2.788 15.9C3.31267 17.1167 4.025 18.175 4.925 19.075C5.825 19.975 6.88333 20.6873 8.1 21.212Z"
          fill="#07D09E"
        />
        <path
          d="M18.3243 10.114L11.7964 16.6415C11.313 17.125 10.5288 17.125 10.045 16.6415L6.67519 13.2715C6.1916 12.788 6.1916 12.0038 6.67519 11.5202C7.15887 11.0365 7.94296 11.0365 8.42642 11.52L10.9209 14.0145L16.5728 8.36262C17.0565 7.87894 17.8406 7.87931 18.3241 8.36262C18.8076 8.84621 18.8076 9.63018 18.3243 10.114Z"
          fill="white"
        />
      </svg>
    </template>
    Submitted for Free Listings
  </tooltip-wrapper>
</template>
