/* eslint-disable */
import { createApp } from "vue";
import { createPinia } from "pinia";

import router from "./router";

//Vue Componenets
import App from "./App.vue";

//--> global and UI components
import SuccessMessage from "./components/UI/SuccessMessage.vue";
import BaseButton from "./components/UI/BaseButton.vue";
import BaseInput from "./components/UI/BaseInput.vue";
import TooltipWrapper from "./components/UI/TooltipWrapper.vue";
import InvalidInputMessage from "./components/UI/InvalidInputMessage";
import CustomDropdown from "./components/UI/CustomDropdown/CustomDropdown";
import ModalOverlay from "./components/UI/ModalOverlay.vue";

//Primevue other components
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import PrimeVue from "primevue/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import Tooltip from "primevue/tooltip";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
// import ConfirmationService from "primevue/confirmationservice";

//Primevue and Bootstrap css
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "tailwindcss/tailwind.css"; //tailwind

const app = createApp(App);
const pinia = createPinia();

app.component("base-button", BaseButton);
app.component("success-message", SuccessMessage);
app.component("base-input", BaseInput);
app.component("tooltip-wrapper", TooltipWrapper);
app.component("invalid-input-message", InvalidInputMessage);
app.component("custom-dropdown", CustomDropdown);
app.component("modal-overlay", ModalOverlay);

// app.component("confirm-message", ConfirmMessage);

//primevue components
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("Row", Row);
app.component("Toast", Toast);
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);

app.use(PrimeVue);
// Vue.use(IconsPlugin);
app.directive("tooltip", Tooltip);
app.use(ToastService);
// app.use(ConfirmationService);

app.use(pinia);
app.use(router);
app.mount("#app");
